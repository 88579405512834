import React from "react";
import img1 from "../../Assets/about/Provideimg1.png";
import img2 from "../../Assets/about/Provideimg2.png";
import img3 from "../../Assets/about/Provideimg3.png";
import { useState } from "react";
import axios from 'axios'
import { useEffect } from "react";

const WhatWeProvideHome = () => {
  const [services, setServices] = useState([]);
  const getAllServices = () => {
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_KEY}/services/getAllServiceByBranchId/674586da9aa6780fb2688681`,
          headers: {}
      };

      axios.request(config)
          .then((response) => {
              // console.log(response.data);
              setServices(response.data)
          })
          .catch((error) => {
              console.log(error);
          });

  }
  useEffect(() => {
      getAllServices();
  }, [])
  return (
    <>
      <div className="flex flex-col gap-4 items-center m-6">
        <h5 className="lato-thin-italic italic text-[#050F64]">Our Services</h5>
        <h1 className="text-3xl md:text-5xl text-center font-serif font-semibold text-[#202020]">
          What We Provide
        </h1>
        <p className="text-center px-4 w-full md:w-[70%] items-center mb-6 capitalize text-[#5F5F5F] font-bold opacity-80">
          Discover a world of tranquility, luxury, and personalized care as you
          embark on a journey of self-care and renewal. Visit us today and let
          us transport you to a realm of beauty and serenity.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6 md:mt-3 lg:mt-0 md:mx-16 mx-6">
        {services.map((service) => (
          <div
            key={service.id}
            className="flex flex-col bg-white rounded-lg shadow-md overflow-hidden hover:scale-105 duration-300"
            data-aos={service.animation}
          >
            <img
              src={service.image}
              alt={service.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h2 className="text-xl font-bold mb-2 font-serif">{service.name}</h2>
              <p className="text-gray-600 text-base tracking-wide mb-4 capitalize">
                {service.description}
              </p>
              <button className="px-4 py-2 mx-auto w-full bg-[#050F64] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out text-white">
              Book Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default WhatWeProvideHome;
