import React from 'react'
import BookNow from '../../Components/Common/BookNow'
import WhatTheySay from '../../Components/Common/WhatTheySay'
import OurHappyTeam from '../../Components/Common/OurHappyTeam'
import AboutUs from '../../Components/Common/AboutUs'
import SpaTreatmentWithPricing from '../../Components/Common/SpaTreatmentsWithPricing'
import WhatWeProvideHome from '../../Components/Home/WhatWeProvideHome'
import HeroSection from '../../Components/Home/HeroSection'
import OurLatestGallery from '../../Components/Home/OurLatestGallery'
import Banner from '../../Components/Common/Banner'

const Home = () => { 
  return (
    <div>
      <HeroSection/>
      <AboutUs />
      <WhatWeProvideHome/>
      <SpaTreatmentWithPricing/>
      <OurLatestGallery />
      <WhatTheySay />
      <OurHappyTeam/>
      <BookNow />
      <Banner />
    </div>
  )
}

export default Home
