import React from "react";
import WhiteQuote from "../../Assets/Common/WhiteQuote.svg";
import BlueQuote from "../../Assets/Common/BlueQuote.svg";
import Rating from "../../Assets/Common/Rating.svg";
import Polygon from "../../Assets/Common/Polygon 1.png";
import Avatar from "../../Assets/Common/Avatar.png";
import { useState, useEffect } from "react";
import axios from 'axios';

function WhatTheySay() {
  const [reviews, setReviews] = useState([]);
  const fetchReviews = async () => {
    let config = {
      method: "get",
      maxBodyLenght: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/reviews/fetchReviews?PLACE_ID=ChIJzxRRZesJGToR_qJNmkmC7HE&API_KEY=XYZ`,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response---->", response.data);
        setReviews(response.data);
      })
      .catch((error) => {
        console.log("Error in fetching reviews", error);
      });
  };

  useEffect(() => {
    fetchReviews();
  }, []);
  const data = [
    {
      id: 1,
      img1Src: BlueQuote,
      img1Alt: "A white quotation symbol",
      content:
        "The massage I received was heavenly. I left feeling relaxed and rejuvenated, ready to take on the world.",
      img2Src: Rating,
      img2Alt: "Stars representing rating",
      img3Src: Polygon,
      img3Alt: "Polygon",
      avatar: Avatar,
      avatarAlt: "Display picture of the person who gave the review",
      title: "Arjun Verma",
      subTitle: "Software Engineer",
    },
    {
      id: 2,
      img1Src: BlueQuote,
      img1Alt: "A blue quotation symbol",
      content:
        "Kaya Spa is my go-to place for relaxation. The staff is attentive, and the treatments are top-notch.",
      img2Src: Rating,
      img2Alt: "Stars representing rating",
      img3Src: {},
      img3Alt: {},
      avatar: Avatar,
      avatarAlt: "Display picture of the person who gave the review",
      title: "Priya Sharma",
      subTitle: "Graphic Designer",
    },
    {
      id: 3,
      img1Src: BlueQuote,
      content: `Visiting Kaya Spa was a delightful experience. The ambiance is soothing, and the services are exceptional.`,
      img2Src: Rating,
      img2Alt: "Stars representing rating",
      img3Src: "",
      img3Alt: "",
      avatar: Avatar,
      avatarAlt: "Display picture of the person who gave the review",
      title: "Ravi Patel",
      subTitle: "Marketing Manager",
    },
  ];

  return (
    <div className="px-6 md:px-16 py-2 flex items-center justify-center flex-col">
      <p className="text-[#050F64] italic">Testimonial</p>
      <h1 className="text-[#202020] font-serif text-center mt-2 md:mt-4 font-extrabold text-3xl md:text-5xl">
        What They Say
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-8">
        {data.map((item) => (
          <div
            key={item.id}
            className="pt-8 items-center justify-center grid grid-cols-1 gap-10"
            data-aos={item.animation}
          >
            <div className="flex flex-col items-start p-6 gap-6 lg:p-8 rounded-lg bg-[#F4FFFD] hover:bg-[#7EDBCB] text-[#050F64] hover:text-white transition-all duration-300 ease-in-out group relative">
              <img src={item.img1Src} alt={item.img1Alt} className="h-8" />

              <p style={{ fontFamily: "Poppins, serif" }}>{item.content}</p>
              <img src={item.img2Src} alt={item.img2Alt} />
              <img
                src={Polygon}
                className="hidden group-hover:block absolute left-0 -bottom-8 h-16"
              />
            </div>

            <div className="flex items-center gap-4">
              <img
                src={item.avatar}
                alt={item.avatarAlt}
                className="h-14 hover:scale-150 duration-500"
              />
              <div className="flex flex-col">
                <h1
                  className="text-lg md:text-2xl font-bold"
                  style={{ fontFamily: "Noto Sans Georgian, sansSerif" }}
                >
                  {item.title}
                </h1>
                <p
                  className="md:text-md"
                  style={{ fontFamily: "Poppins, serif" }}
                >
                  {item.subTitle}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatTheySay;
