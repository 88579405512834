import React from "react";

function Map() {
  return (
    <div className="py-2">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.2238107141516!2d85.8407078773564!3d20.291001164206858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909eb655114cf%3A0x71ec82499a4da2fe!2sKAYA%20SPA!5e0!3m2!1sen!2sin!4v1734677079416!5m2!1sen!2sin"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        className="w-full h-[250px] md:h-[400px] md:pb-8"
        data-aos="zoom-in"
      ></iframe>
    </div>
  );
}

export default Map;
