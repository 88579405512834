import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

function OurLatestGallery() {
  //State to store all the gallery photos of a branch
  const [allGallery, setAllGallery] = useState([]);

  //Function to fetch all the gallery photos of a branch
  const showAllgalleryData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/gallery/getGallery/674586da9aa6780fb2688681`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response---->", response.data);
        setAllGallery(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("Gallery----->", allGallery);

  useEffect(() => {
    showAllgalleryData();
  }, []);
  return (
    <div className="p-8 md:p-16 md:pt-12 flex flex-col">
      <h5 className="lato-thin-italic italic text-[#050F64] text-center md:mb-2">
        Gallery
      </h5>
      <h1 className="text-3xl md:text-5xl text-center font-serif font-semibold text-[#202020] mb-4 md:mb-8">
        Our Latest Gallery
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
        <div className="flex flex-col gap-4 md:gap-6 lg:gap-8">
          <img
            src={allGallery[0]?.gallery}
            alt="A lady is having massage"
            data-aos="flip-left"
          />
          <img
            src={allGallery[1]?.gallery}
            alt="Oil"
            data-aos="flip-left"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="flex flex-col gap-4 md:gap-6 lg:gap-8">
          <img
            src={allGallery[2]?.gallery}
            alt="Flowers"
            data-aos="flip-left"
            className="w-full h-full object-cover"
          />
          <img
            src={allGallery[3]?.gallery}
            alt="A lady is having massage"
            data-aos="flip-left"
          />
        </div>

        <div className="flex flex-col gap-4 md:gap-6 lg:gap-8">
          <img
            src={allGallery[4]?.gallery}
            alt="A lady is having massage"
            data-aos="flip-left"
          />
          <img
            src={allGallery[5]?.gallery}
            alt="Massage Ingredients"
            data-aos="flip-left"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default OurLatestGallery;
