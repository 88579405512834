import React from "react";
import formBgImage from "../../Assets/Common/formBgImage.svg";
import { useState } from "react";

function BookNow() {
  function DateFieldWithPlaceholder() {
    const [inputType, setInputType] = useState("text");
    const [date, setDate] = useState("");

    const handleFocus = () => {
      setInputType("date");
    };

    const handleBlur = () => {
      if (!date) {
        setInputType("text"); // Revert to text if no date is selected
      }
    };

    return (
      <input
        type={inputType}
        value={date}
        onChange={(e) => setDate(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id="date"
        className="bg-inherit p-4 pl-0  border-b-2 border-opacity-25 tracking-wide border-white placeholder-white focus:outline-none w-full"
        placeholder="Select date"
      />
    );
  }

  return (
    <div>
      <div
        style={{
          
          backgroundImage: `url(${formBgImage})`,
          backgroundSize: "cover",
        }}
        className="p-6 pl-4 pr-2 my-8 md:pr-24 flex justify-center md:justify-end text-white bg-right md:bg-auto"
      >
        <div className="flex p-4 flex-col items-start justify-center md:justify-start">
          <h1
            className="font-bold text-2xl md:text-4xl tracking-wide"
            style={{ fontFamily: "Noto Sans Georgian, sansSerif" }}
          >
            Book Now
          </h1>
          <p className="mt-2 mb-4 text-base" style={{ fontFamily: "Poppins, serif" }}>
            We’d Love to Hear from you!
          </p>

          <form action="" className="flex flex-col">
            <div className="flex md:flex-row md:gap-12 flex-col ">
              <input
                type="text"
                className="bg-inherit p-4 pl-0  border-b-2 border-opacity-25 tracking-wide border-white placeholder-white focus:outline-none w-full"
                placeholder="Your Name"
              />

              <input
                type="text"
                className="bg-inherit p-4 pl-0  border-b-2 border-opacity-25 tracking-wide border-white placeholder-white focus:outline-none w-full"
                placeholder="Email Address"
              />
            </div>

            <div className="flex justify-between md:flex-row md:gap-12 flex-col">
              <select
                id="options"
                className="bg-inherit w-full p-4 pl-0 border-b-2 border-opacity-25 tracking-wide border-white placeholder-white focus:outline-none"
              >
                <option value="" disabled selected hidden>
                  Select service
                </option>
                <option value="Body Massages" className="text-black text-sm">
                  Body Massages
                </option>
                <option value="Oil Therapy" className="text-black text-sm">
                  Oil Therapy
                </option>
                <option value="Aroma Therapy" className="text-black text-sm">
                  Aroma Therapy
                </option>
              </select>

              <DateFieldWithPlaceholder/>
            </div>

            <div className="flex">
            <input
                type="text"
                className="bg-inherit h-24 align-text-top p-4 pl-0  border-b-2 border-opacity-25 tracking-wide border-white placeholder-white focus:outline-none w-full"
                placeholder="Write a message"
              />
            </div>

            <button className="px-4 py-2 mt-6 w-full bg-[#050F64] rounded-md md:w-32 hover:bg-[#008db4] duration-300 ease-in-out">Book Now</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BookNow;
